<template>
  <article class="non-interactive">
    <span class="back-btn text-no-wrap" @click="$router.push('/tickets')">
      <v-icon>mdi-arrow-left</v-icon>
        Back
    </span>
    <header>
      CONGRATULATIONS
    </header>

    <main>
      You've booked a ticket for the non-interactive show. Click below to begin viewing!
    </main>

    <footer>
      <v-btn text large class="show-btn" to="/pod/1">
        ENTER THE SHOW
      </v-btn>
    </footer>
  </article>
</template>

<script>
import awsAuth from '@/cognito/aws-auth';

export default {
  data() {
    return {
      concertId: 9987
    }
  },
  async mounted() {
    const response = await awsAuth.userData();
    if(response.status === 200){
      let body = { }
      body['custom:concerts'] = `${this.concertId}`;
      await awsAuth.updateAttributes(body);
    }
  }
}
</script>

<style lang="scss" scoped>
.non-interactive {
  width: 90%;
  height: 100%;

  margin: auto;
  padding: 2rem;

  display: flex;
  flex-flow: column nowrap;

  justify-content: center;
  align-items: center;
}

.non-interactive header {
  text-transform: uppercase;

  font-size: 3rem;
  font-weight: 800;

  margin-bottom: 1rem !important;

  text-align: center;
}

.non-interactive main {
  padding: 2rem;

  font-size: 1.5rem;
}

.show-btn {
  background-color: #FF3C11;
}
.back-btn {
  position: absolute;
  top: 50px;
  left: 50px;

  cursor: pointer;
  font-size: 30px;
  font-weight: 300;
  letter-spacing: 2px;

  .v-icon {
    font-size: 30px;
  }
}
</style>
